import React, { useEffect, useState } from "react"
import { Container, Row, Col, Table } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import HTMLReactParser from "html-react-parser"

// Styles
import "./DashboardDetails.scss"

// Components
import DashboardFilter from "components/Dashboard/DashboardFilter"
import AvailableButtons from "components/Dashboard/AvailableButtons"
import TableList from "components/Dashboard/TableList"

import {saleType} from "components/common/propertyUtils"

const DashboardDetails = ({user_data}) => {

    const data = useStaticQuery(graphql`
     query DashboardDetails {  
       glstrapi{
        globalModule{
            Dashboard_Content
        }
       }
     }
   `)
   var dashboard = data.glstrapi?.globalModule;

   const[status, setStatus] = useState(saleType[ 0 ].value);
   const[currentWeek, setCurrentWeek] = useState(false);
   const[buildingType, setBuildingType] = useState('');
   const[bedrooms, setBedrooms] = useState('');
   const[pageNo, setPageNo] = useState(1);

   const agentBranchArea = user_data?.logged_user_data?.branch_info?.branch_area || ''; 
   const[agentArea, setAgentArea] = useState(agentBranchArea);

   const [initialAgentArea, setInitialAgentArea] = useState(null);

   useEffect(() => {
     setInitialAgentArea(agentBranchArea);
   }, []);

   const [activeButton, setActiveButton] = useState(0);

    return (
        <div className="dashboard-details-wrapper">
            {user_data?.logged_user_data? 
                <Container>
                    <Row className="dashboard-details-header">
                        <Col lg="12">
                            <h1 className="general-heading">Welcome <sapn className="user_name">{user_data.logged_user_data?.contact_info?.nego_name}</sapn>,</h1>
                            {dashboard.Dashboard_Content && HTMLReactParser(dashboard.Dashboard_Content)}
                       
                            {/* ==== Available Buttons ==== */}
                            <AvailableButtons setCurrentWeek={setCurrentWeek} currentWeek={currentWeek} setStatus={setStatus}  status={status} agentArea={agentArea} setAgentArea={setAgentArea} activeButton={activeButton} setActiveButton={setActiveButton} initialAgentArea= {initialAgentArea} />
                        </Col>
                    </Row>
                    <Row className="dashboard-details-body">
                        <Col>
                            <h2 className="general-heading">Filters</h2>

                            {/* ==== Dashboard Filter ==== */}
                            <DashboardFilter setStatus={setStatus} status={status} setBuildingType={setBuildingType} buildingType={buildingType} setBedrooms={setBedrooms} bedrooms={bedrooms} pageNo={pageNo} setPageNo={setPageNo} agentArea={agentArea} setAgentArea={setAgentArea} initialAgentArea= {initialAgentArea} setActiveButton={setActiveButton} />


                            {/* ==== Table List ==== */}
                            <TableList status={status} buildingType={buildingType} bedrooms={bedrooms} pageNo={pageNo} setPageNo={setPageNo} currentWeek={currentWeek} agentArea={agentArea}/>
                            
                        </Col>
                    </Row>
                </Container>
            :
                <Container>
                    <Row className="dashboard-details-header">
                        <Col lg="10">
                            <h1 className="general-heading">Hif <sapn className="user_name">{user_data.logged_user_data?.contact_info?.nego_name}</sapn>,</h1>
                            <p>Thanks for registered with Agent, We will verify your information as soon as possible, we will send confirmation message to your registered email-id shortly.</p>
                        </Col>
                    </Row>

                </Container>
            }
        </div>
    )
}

export default DashboardDetails
